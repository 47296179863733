<template>
  <div id="contenido-pagina" class="container">
    <div class="alert alert-danger text-center">
      <div class="font-weight-bold">Página no encontrada</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
